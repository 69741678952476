<script lang="ts">
  import type { schema } from "@editor/schema";
  import Block from "./EmailBlock.svelte";
  import { getNewsletterStores } from "../../newsletterStores";
  import { ctaToHref, LinkContent } from "../utils";

  export let block: schema.ButtonBlock;
  const { design, ctx } = getNewsletterStores();

  $: color = $design!.background.dark ? "#fff" : $design!.color.button.text;

  $: bgcolor = $design!.color.button.background;
</script>

<Block {block} let:layout>
  <table width="100%" cellpadding="12" cellspacing="0">
    <tr>
      <td width="100%" {color} {bgcolor} style="border-radius:4px;overflow:hidden" valign="center" align="center">
        <LinkContent cta={block.cta} type="button" blockId={block._id} linkText={block.text} style="display:block">
          <span style="font:bold 21px sans-serif;color:{color};text-decoration:none;line-height:1">{block.text}</span>
        </LinkContent>
      </td>
    </tr>
  </table>

  {#if block.details}
    <p
      style="opacity:0.75;margin-top:8px;line-height: 1.375;font-size:14px;text-align:center;color:{$design.background.dark
        ? '#fff'
        : $design.color['global.text']}">
      {#each block.details.trim().split("\n") as l}{l}<br />{/each}
    </p>
  {/if}
</Block>
