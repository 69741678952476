<script lang="ts" context="module">
  import type { schema } from "@editor/schema";
  import { getNewsletterStores } from "../newsletterStores";
  import Block from "./Block.svelte";
  import { withDefaults, LinkContent } from "./utils";
</script>

<script lang="ts">
  import { ctaToHref } from "./utils";

  export let block: schema.ButtonBlock;
  const { design, ctx } = getNewsletterStores();

  $: [b, isDefault] = withDefaults(block, { text: "Click to add text" });
</script>

<Block {block} on:click on:delete on:duplicate let:isEmpty showEmpty layouts={["-"]}>
  <div class="w-full text-center">
    <LinkContent
      role="button"
      cta={b.cta}
      showEmpty
      type="button"
      class="block w-full p-3 text-lg font-bold rounded shadow-md clickable ada-bg-black ada-text-white"
      style="color:{isDefault(b.text) ? '#666' : $design.color.button.text}; background-color: {isDefault(b.text)
        ? '#ccc'
        : $design.color.button.background}"
      data-focus-key="text">
      {b.text}
    </LinkContent>
    {#if b.details}
      <div
        data-focus-key="details"
        class:opacity-50={isDefault(b.details)}
        class:opacity-75={!isDefault(b.details)}
        class="mt-2 leading-snug text-14 ada-text-black"
        style="color:{$design.background.dark ? '#fff' : $design.color['global.text']}">
        {#each b.details.trim().split("\n") as l}{l}<br />{/each}
      </div>
    {/if}
  </div>
</Block>
