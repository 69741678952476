import type { schema } from "@editor/schema";
import type { SvelteComponent } from "svelte";
import FallbackBlock from "./FallbackBlock.svelte";
import TitleBlock from "./TitleBlock.svelte";
import TitleBlockEmail from "./email/TitleBlockEmail.svelte";
import TitleBlockEditor from "./TitleBlockEditor.svelte";
import ButtonBlock from "./ButtonBlock.svelte";
import ButtonBlockEmail from "./email/ButtonBlockEmail.svelte";
import ButtonBlockEditor from "./ButtonBlockEditor.svelte";
import ParagraphBlock from "./text/ParagraphBlock.svelte";
import ParagraphBlockEmail from "./email/ParagraphBlockEmail.svelte";
import ParagraphBlockEditor from "./text/ParagraphBlockEditor.svelte";
import ImageBlock from "./ImageBlock.svelte";
import ImageBlockEmail from "./email/ImageBlockEmail.svelte";
import ImageBlockEditor from "./ImageBlockEditor.svelte";
import VideoBlock from "./VideoBlock.svelte";
import VideoBlockEmail from "./email/VideoBlockEmail.svelte";
import VideoBlockWeb from "./VideoBlockWeb.svelte";
import VideoBlockEditor from "./VideoBlockEditor.svelte";
import FileBlock from "./FileBlock.svelte";
import FileBlockEmail from "./email/FileBlockEmail.svelte";
import FileBlockEditor from "./FileBlockEditor.svelte";
import HeaderBlockEditor from "./HeaderBlockEditor.svelte";
import SignatureBlockEditor from "./SignatureBlockEditor.svelte";
import SignatureBlock from "./SignatureBlock.svelte";
import HeaderBlock from "./HeaderBlock.svelte";
import ItemsBlock from "./ItemsBlock.svelte";
import ItemsBlockEmail from "./email/ItemsBlockEmail.svelte";
import ItemsBlockEditor from "./ItemsBlockEditor.svelte";
import LinkBlock from "./LinkBlock.svelte";
import LinkBlockEmail from "./email/LinkBlockEmail.svelte";
import LinkBlockEditor from "./LinkBlockEditor.svelte";
import EventBlock from "./EventBlock.svelte";
import EventBlockEmail from "./email/EventBlockEmail.svelte";
import EventBlockEditor from "./EventBlockEditor.svelte";
import SeparatorBlock from "./SeparatorBlock.svelte";
import SeparatorBlockEmail from "./email/SeparatorBlockEmail.svelte";
import PlaceholderBlock from "./PlaceholderBlock.svelte";
import LogoBlock from "./LogoBlock.svelte";
import LogoBlockWeb from "./LogoBlockWeb.svelte";
import LogoBlockEditor from "./LogoBlockEditor.svelte";
import IFrameBlock from "./IFrameBlock.svelte";
import IFrameBlockEditor from "./IFrameBlockEditor.svelte";
import IFrameBlockEmail from "./email/IFrameBlockEmail.svelte";
import PollBlock from "./PollBlock.svelte";
import PollBlockEmail from "./email/PollBlockEmail.svelte";
import PollBlockEditor from "./PollBlockEditor.svelte";
import { lockInfo } from "@editor/api";
import { isEditor } from "./utils";
import { id as generateId } from "./utils";
import { fromDate, toStartOfDay } from "./poll-block-editor/durationUtils";

export { FallbackBlock };

type BlockConfig<T extends schema.Block = schema.Block> = {
  // Default rendering
  render: typeof SvelteComponent;
  // Editing form
  editor?: typeof SvelteComponent;
  // Special empty case override
  empty?: typeof SvelteComponent;
  // Special web consumption override
  web?: typeof SvelteComponent;
  // Email special consumption
  email?: typeof SvelteComponent;

  displayName?: string;
  createNew?: (id: string, layout?: string) => T;
  isEmpty: (item: T) => boolean;

  // Spacing functions, for whatever comes after you, and what comes before you
  // returns the space in pixels before and after, undefined => default space
  mt?: SpacingFunction<T>;
  mb?: SpacingFunction<T>;
};

type SpacingFunction<T extends schema.Block> = Partial<
  {
    [P in schema.BlockType]: (self: T, block: schema.BlockMap[P]) => number | undefined;
  }
>;

export type BlocksConfig = {
  [P in schema.BlockType]: BlockConfig<schema.BlockMap[P]>;
};

export const blocksConfig: BlocksConfig = {
  "embed.file": {
    render: FileBlock,
    editor: FileBlockEditor,
    email: FileBlockEmail,
    displayName: "Attachment",
    createNew: (id: string) => ({
      ...empty(id, "embed.file"),
      _li: lockInfo("edit")
    }),
    isEmpty(b) {
      return isValueEmpty(b?.access_url);
    }
  },
  "embed.iframe": {
    render: IFrameBlock,
    editor: IFrameBlockEditor,
    email: IFrameBlockEmail,
    displayName: "Form",
    createNew: (id: string) => ({
      ...empty(id, "embed.iframe"),
      _li: lockInfo("edit")
    }),
    isEmpty(b) {
      return isValueEmpty(b?.iframe_url);
    }
  },
  "button": {
    render: ButtonBlock,
    editor: ButtonBlockEditor,
    displayName: "Button",
    email: ButtonBlockEmail,
    createNew: (id: string) => ({
      ...empty(id, "button"),
      _li: lockInfo("edit"),
      text: "",
      details: "",
      cta: {
        id: id + "_cta",
        url: ""
      }
    }),
    isEmpty(b) {
      return isValueEmpty(b.cta?.url);
    }
  },
  "embed.link": {
    render: LinkBlock,
    editor: LinkBlockEditor,
    email: LinkBlockEmail,
    displayName: "Link",
    createNew: (id: string) => ({
      ...empty(id, "embed.link"),
      _li: lockInfo("edit")
    }),
    isEmpty(b) {
      return isValueEmpty(b?.url);
    }
  },
  "embed.video": {
    displayName: "Video",
    render: VideoBlock,
    editor: VideoBlockEditor,
    email: VideoBlockEmail,
    web: VideoBlockWeb,
    createNew: (id: string) => ({
      ...empty(id, "embed.video"),
      _li: lockInfo("edit")
    }),
    isEmpty(b) {
      return isValueEmpty(b?.url || b?.movie);
    }
  },
  "event": {
    displayName: "Event",
    render: EventBlock,
    editor: EventBlockEditor,
    email: EventBlockEmail,
    createNew: (id: string) => ({
      ...empty(id, "event"),
      _li: lockInfo("edit")
    }),
    isEmpty(b) {
      return isValueEmpty(b?.name);
    }
  },
  "poll": {
    displayName: "Poll",
    render: PollBlock,
    editor: PollBlockEditor,
    email: PollBlockEmail,
    createNew: (id: string) => {
      const startDate = toStartOfDay(new Date());
      startDate.setMinutes(1440);
      return {
        ...empty(id, "poll"),
        answers: [
          { text: "", id: generateId("answer") },
          { text: "", id: generateId("answer") }
        ],
        dateRange: fromDate(startDate, 1439),
        showLiveResults: false,
        multiAnswers: false,
        _li: lockInfo("edit")
      };
    },
    isEmpty(b) {
      return isValueEmpty(b?.question);
    }
  },
  "image.gallery": {
    displayName: "Photo Gallery",
    render: FallbackBlock,
    isEmpty(b) {
      return true;
    }
  },
  "image.single": {
    displayName: "Big Photo",
    render: ImageBlock,
    editor: ImageBlockEditor,
    email: ImageBlockEmail,
    createNew: (id: string) => ({
      ...empty(id, "image.single"),
      _li: lockInfo("edit")
    }),
    isEmpty(b) {
      return isValueEmpty(b?.photo);
    }
  },
  "misc.placeholder": {
    render: PlaceholderBlock,
    createNew: (id: string, layout: string) => ({
      ...empty(id, "misc.placeholder", layout),
      _s: undefined
    }),
    isEmpty(b) {
      return !isEditor();
    }
  },
  "misc.separator": {
    displayName: "Separator",
    render: SeparatorBlock,
    email: SeparatorBlockEmail,
    createNew: (id: string) => ({
      ...empty(id, "misc.separator", "sm", "solid"),
      _s: undefined
    }),
    isEmpty(b) {
      return false;
    }
  },
  "text.paragraph": {
    displayName: "Paragraph",
    render: ParagraphBlock,
    editor: ParagraphBlockEditor,
    email: ParagraphBlockEmail,
    createNew: (id) => ({
      ...empty(id, "text.paragraph"),
      _li: lockInfo("edit")
    }),
    mb: {
      "text.paragraph": (t: schema.ParagraphBlock, b: schema.ParagraphBlock) => {
        return b.title ? 20 : 14;
      },
      "text.title": (t: schema.ParagraphBlock, b: schema.TitleBlock) => {
        switch (b._l) {
          case "lg":
            return 32;
          case "md":
            return 28;
          case "sm":
            return 24;
        }
      }
    },
    isEmpty(b) {
      return isValueEmpty(b.content) && isValueEmpty(b.picture);
    }
  },
  "items": {
    displayName: "Item List",
    render: ItemsBlock,
    editor: ItemsBlockEditor,
    email: ItemsBlockEmail,
    createNew: (id) => ({
      ...empty(id, "items", "3-columns"),
      items: [],
      _li: lockInfo("edit")
    }),
    // Empty when all items are empty
    isEmpty(b) {
      return isValueEmpty(b.items) && b.items.every(isValueEmpty);
    }
  },
  "text.title": {
    displayName: "Title",
    render: TitleBlock,
    editor: TitleBlockEditor,
    email: TitleBlockEmail,
    createNew: (id: string) => ({
      ...empty(id, "text.title", "sm"),
      _toc: true,
      _li: lockInfo("edit")
    }),
    // empty when there's no title
    isEmpty(b) {
      return isValueEmpty(b.title);
    },
    mb: {
      "text.paragraph": (t: schema.TitleBlock, b: schema.ParagraphBlock) => {
        let s = 10;
        if (t._l === "lg") {
          s = 16;
        }
        if (t._l === "md") {
          s = 14;
        }
        return b.title ? 24 : s;
      },
      "button": (t: schema.TitleBlock, b: schema.ButtonBlock) => {
        return 20;
      },
      "text.title": (t: schema.TitleBlock, b: schema.TitleBlock) => {
        return 14;
      }
    }
  },
  "logo": {
    render: LogoBlock,
    editor: LogoBlockEditor,
    web: LogoBlockWeb,
    isEmpty(b) {
      return isValueEmpty(b?.picture);
    }
  },
  "header": {
    render: HeaderBlock,
    editor: HeaderBlockEditor,
    isEmpty(b) {
      return isValueEmpty(b?.title);
    }
  },
  "signature": {
    render: SignatureBlock,
    editor: SignatureBlockEditor,
    isEmpty(b) {
      return isValueEmpty(b?.title);
    }
  }
};

export function newBlock<T extends schema.Block>(id: string, blockType: schema.BlockType, layout: string): T {
  let b = empty(id, blockType, layout);

  if (blocksConfig[blockType].createNew) {
    b = { ...b, ...blocksConfig[blockType].createNew!(id, layout) };
  }

  return b as T;
}

export function isEmpty<T extends schema.Block>(b: T): boolean {
  if (!b || !b._t) {
    return true;
  }

  const checkEmpty = blocksConfig[b._t].isEmpty;

  // @ts-ignore
  return checkEmpty ? checkEmpty(b) : false;
}

export function isValueEmpty(v: any) {
  if (v === null || v === undefined || v === "") {
    return true;
  }

  if ((Array.isArray(v) && !v.length) || (typeof v === "object" && !Object.values(v).length)) {
    return true;
  }
  return false;
}

function empty(id: string, blockType: string, layout?: string, style?: string) {
  const l = layout ? { _l: layout } : {};
  const s = style ? { _style: style } : {};
  return {
    _id: id,
    _t: blockType,
    ...l, // save layout if exists
    ...s // save style if exists
  };
}
