<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { IconsMap } from "./icons";
  import type { schema } from "@editor/schema";
  import { fly } from "svelte/transition";

  export let isEditing: boolean = false;
  export let block: schema.Block;
  export let styles: schema.BlockStyle[];
  export let previewStyle: undefined | schema.BlockStyle;

  let isOpen = false;
  const dispatch = createEventDispatcher();

  function getVariationIconName(style: schema.BlockStyle) {
    return IconsMap[`${block._t}.${style}`];
  }

  function cancel(e: MouseEvent) {
    e.preventDefault();
    e.stopImmediatePropagation();
    dispatch("cancel");
    previewStyle = undefined;
    isOpen = false;
  }

  const preview = {
    commit(newStyle: schema.BlockStyle) {
      isOpen = false;
      dispatch("style_changed", { newStyle });
    },

    clear() {
      previewStyle = undefined;
    },

    set(newStyle: schema.BlockStyle) {
      previewStyle = newStyle;
    }
  };

  function open() {
    isOpen = true;
    dispatch("open");
  }
</script>

{#if isOpen}
  <div class="fixed inset-0 z-20 bg-transparent cursor-default" on:click|capture|preventDefault|stopPropagation={cancel} />
{/if}
<div class="relative flex items-center self-center justify-center edit-item" on:click|preventDefault|stopPropagation={open}>
  {#if isOpen}
    <!-- This wrapper is used to allow the user move the mouse around and near the buttons without hiding them -->
    <div
      style="z-index: 205"
      class="absolute flex flex-col -m-3 -mt-3 cursor-default style-selector bg-opacity-20"
      in:fly={{ duration: 150, y: -4, opacity: 0.9 }}
      out:fly={{ duration: 50, y: -4, opacity: 0.9 }}>
      <div class="flex px-1 py-2 bg-black rounded shadow bg-opacity-90">
        <slot {preview} />
        <div class="flex">
          {#each styles as variation}
            <div
              class="w-12 h-10 px-1 group style-variation clickable scale-in"
              on:click|preventDefault|stopPropagation={() => preview.commit(variation)}
              on:mouseenter={() => preview.set(variation)}
              on:mouseleave={preview.clear}>
              <div
                class:bg-gray-100={variation === block._style}
                class:bg-nxgray-600={variation !== block._style}
                class:group-hover:bg-nxgray-500={variation !== block._style}
                class="flex items-center justify-center w-full h-full transition-all bg-center bg-no-repeat rounded"
                style="background-image: url({getVariationIconName(variation)})" />
            </div>
          {/each}
        </div>
      </div>
      <i class="absolute bottom-0 mx-auto -mb-3.5 -ml-3 left-1/2 material-icons md-24 text-nxgray-700">arrow_drop_down</i>
    </div>
  {/if}
  <div
    class:bg-green-700={isOpen}
    class:bg-brand-gray-80={!isOpen}
    class="flex items-center self-center justify-center h-6 px-2 mr-1 text-sm text-white transition-colors rounded cursor-pointer clickable scale-in hover:bg-green-700 hover:text-white">
    Style
  </div>
</div>

<style>
  .style-selector {
    top: -3rem;
  }
</style>
