<script lang="ts">
  import type { schema } from "@editor/schema";
  import { attrPatch, renderHTML, toText } from "@ui/rich";
  import type { RichTag } from "@ui/rich";
  import { getBlockId, isEditor, trackingAndAttributesFromCTA } from "./index";
  import { getRenderContext } from "../../newsletterStores";
  import { isRTL } from "sbelt/text";

  type InlineOption = {
    color?: string;
    linkColor?: string;
    fontSize?: number;
  };

  export let content: schema.RichText;
  export let inline: InlineOption | boolean = false;
  export let forceTracking: boolean = false;
  export let context: { type: "paragraph" | "photo" | "event" | "signature" };

  const blockId = getBlockId();

  const rtl = isRTL(toText(content));

  $: color = typeof inline === "object" ? inline.color || "#111" : "#111";
  $: linkColor = typeof inline === "object" ? inline.linkColor || "#1c6e98" : "#1c6e98";
  $: fontSize = typeof inline === "object" ? inline.fontSize || "inherit" : "inherit";
  $: fontAttributes = `font-size:${fontSize}px;font-family:sans-serif`;

  function hasStyle(n: RichTag, styleTest?: RegExp) {
    return n && n.a?.style && (styleTest || /.+/gi).test(n.a.style);
  }

  function isEmpty(n: RichTag) {
    // empty if no content / content is an empty string
    return !n || !n.c || !n.c.length || n.c[0] === "";
  }

  const ctx = getRenderContext();

  function transform(tag: RichTag): RichTag {
    switch (tag.t) {
      case "a":
        // handle link transform here
        const id = tag.a?.["data-id"];

        const href = tag.a?.["href"];
        if (href) {
          const [_, attrs] = trackingAndAttributesFromCTA(
            ctx,
            { id: id!, url: href },
            { id: id!, type: context.type, text: toText([tag]), blockId }
          );
          // yay let's update the proper urls here
          if (!inline || forceTracking) {
            // @ts-ignore
            attrs["class"] = "skip-tracking";
          }
          tag.a = { ...tag.a, ...attrs };
        }

        // For outlook, we need to wrap all of our link content in a big span, because, you know, outlook is the worst
        // https://www.emailonacid.com/blog/article/email-development/how-to-code-emails-for-outlook-2016/#lines
        if (inline || forceTracking) {
          tag.c = [{ t: "span", c: tag.c, a: { style: `text-decoration:underline;${fontAttributes};color:${linkColor}` } }];
        }

        break;
      case "u":
      case "strong":
        // inline && attrPatch(n, "style", (s) => s + "font-weight:bold");
        break;
      case "em":
        // inline && attrPatch(n, "style", (s) => s + "font-style:italic");
        break;
      case "ul":
      case "ol":
        inline && attrPatch(tag, "style", (s) => `${s || ""}${fontAttributes};padding:0 0 0 1em;margin:0;`);
        break;
      case "p":
      case "span":
        inline &&
          !isEmpty(tag) &&
          !hasStyle(tag, /underline/) &&
          attrPatch(
            tag,
            "style",
            (s) => `${s || ""}${tag.a?.align ? `text-align:${tag.a?.align}!important;` : ""}margin:0;${fontAttributes};color:${color};`
          );
    }
    return tag;
  }
</script>

<div
  class:rich={!inline}
  class:rich-i={inline}
  class:pointer-events-none={isEditor()}
  dir={rtl ? "rtl" : "ltr"}
  align={rtl ? "right" : "left"}
  style={inline ? `${fontAttributes};color:${color}` : ""}>
  {@html renderHTML(content, transform)}
</div>

<style>
  .rich {
    word-break: break-word;
  }

  .rich :global(b),
  .rich :global(strong) {
    font-weight: bold;
  }

  .rich :global(i),
  .rich :global(em) {
    font-style: italic;
  }

  .rich :global(ul),
  .rich :global(ol) {
    padding-left: 30px;
  }

  .rich :global(ul li) {
    list-style: disc;
  }

  .rich :global(ol li) {
    list-style: decimal;
  }

  .rich :global(a) {
    text-decoration: underline;
    color: var(--link-color, #1c6e98);
  }

  .rich :global(p[align="left"]) {
    text-align: left !important;
  }

  .rich :global(p[align="right"]) {
    text-align: right !important;
  }

  .rich :global(p[align="center"]) {
    text-align: center !important;
  }

  .rich :global(p[align="justify"]) {
    text-align: justify !important;
  }

  .rich :global(p),
  .rich :global(u) {
    break-after: auto;
  }
</style>
