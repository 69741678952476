<script lang="ts">
  import type { schema } from "@editor/schema";
  import Rich from "../utils/Rich.svelte";
  import Block from "../Block.svelte";
  import PhotoField from "../form/PhotoField.svelte";
  import { getNewsletterStores } from "../../newsletterStores";
  import { isEditor, photoToCTA, withDefaults, LinkContent, fancyPicClassIfNoCTA } from "../utils";
  import { isEmpty } from "../blocksConfig";
  import { isRTL } from "sbelt/text";

  export let block: schema.ParagraphBlock;
  const { design, content, ctx } = getNewsletterStores();

  function photoUpdated(p: schema.Photo | undefined) {
    content.updateById<schema.ParagraphBlock>(block._id, (b) => {
      b.picture = p;
    });
  }

  // get defaults
  $: [b, isDefault] = withDefaults(block);
</script>

<Block
  bind:block
  on:click
  on:delete
  let:layout
  layouts={["-", "side-l", "side-r"]}
  let:isEmpty
  showEmpty
  preventClicks={!isEmpty}
  let:logged_out>
  <div>
    <!-- Photo -->
    {#if layout === "side-l" || (layout === "side-r" && (block.picture || isEditor()))}
      <div
        on:click|stopPropagation
        class="relative mb-4 overflow-hidden rounded pointer-events-auto sm:mb-1 print:mb-1 group"
        class:sm:w-48={(b.picture && b.picture.legacy_thumb_url) || isEditor()}
        class:print:w-48={(b.picture && b.picture.legacy_thumb_url) || isEditor()}
        class:sm:float-left={layout === "side-l"}
        class:sm:mr-6={layout === "side-l" && ((b.picture && b.picture.legacy_thumb_url) || isEditor())}
        class:sm:float-right={layout === "side-r"}
        class:sm:ml-4={layout === "side-r"}
        class:print:float-left={layout === "side-l"}
        class:print:mr-6={layout === "side-l" && ((b.picture && b.picture.legacy_thumb_url) || isEditor())}
        class:print:float-right={layout === "side-r"}
        class:print:ml-4={layout === "side-r"}>
        {#if b.picture && b.picture.legacy_thumb_url && !isEditor()}
          <LinkContent
            showEmpty
            cta={photoToCTA(b.picture)}
            class="relative block w-full h-full skip-tracking image-container {fancyPicClassIfNoCTA(b.picture)}"
            data-title={b.picture.title}
            data-img-full={b.picture.legacy_full_url}
            type="paragraph-image"
            linkText={b.picture.alt_text}>
            <img
              src={b.picture.legacy_thumb_url}
              alt={b.picture.alt_text}
              class="object-contain max-w-full max-h-full bg-transparent rounded" />
            <div
              role="img"
              aria-label={b.picture.alt_text}
              title={b.picture.alt_text}
              class="absolute inset-0 opacity-0 group-hover:bg-gray-100 group-hover:bg-opacity-10 group-hover:opacity-100" />
            <div class="absolute top-0 right-0 opacity-0 group-hover:opacity-100">
              <div class="flex items-center">
                {#if b.picture.cta?.url}
                  <LinkContent
                    showEmpty
                    cta={photoToCTA(b.picture)}
                    class="relative flex p-1 mr-2 rounded bg-opacity-80 bg-nxgray-500 skip-tracking"
                    data-title={b.picture.title}
                    data-img-full={b.picture.legacy_full_url}
                    type="paragraph-image"
                    linkText={b.picture.alt_text}
                    title="Open link"
                    tabindex="-1">
                    <span aria-hidden="true" class="text-white material-icons notranslate text-18"> open_in_new </span>
                  </LinkContent>
                {/if}
                <a
                  class="p-1 rounded bg-opacity-80 fancy-pic bg-nxgray-500 skip-tracking"
                  href={b.picture.legacy_full_url}
                  title="Expand"
                  tabindex="-1"
                  ><span aria-hidden="true" class="text-white material-icons notranslate text-18"> zoom_out_map </span>
                </a>
              </div>
            </div>
          </LinkContent>
        {:else if isEditor()}
          <PhotoField
            value={block.picture}
            handoffId="side-photo-{block._id}"
            on:upload_done={(e) => photoUpdated(e.detail)}
            on:clear={(e) => photoUpdated(undefined)}
            on:logged_out={logged_out}
            mode="crop"
            showErrorMessage />
        {/if}
      </div>
    {/if}

    <!-- Title -->
    {#if b.title}
      <h3
        class="mb-2 font-bold leading-tight text-gray-600 text-18"
        dir={isRTL(b.title) ? "rtl" : "ltr"}
        class:opacity-50={isDefault(b.title)}
        data-field-key="title"
        class:text-center={b.align === "center"}
        style="color:{$design.background.dark ? '#fff' : $design.color['global.text']}">
        {b.title}
      </h3>
    {/if}

    {#if b.content}
      <div
        class="text-15"
        class:opacity-50={isDefault(b.content)}
        style="color:{$design.background.dark ? '#fff' : $design.color['global.text']}"
        data-field-key="content">
        <Rich content={b.content} context={{ type: "paragraph" }} />
      </div>
    {/if}

    <div class="clear-both" />
  </div>
</Block>

<style>
  .fancy-pic {
    position: relative !important;
    display: flex !important;
  }
</style>
