import type { schema } from "@editor/schema";
import type { NewsletterAPI, APIStatus, BlockUpdater } from "./types";
import { writable, readable } from "svelte/store";
import debounce from "lodash.debounce";
import cloneDeep from "lodash.clonedeep";
import { minWait } from "sbelt/promise";
import { postJson, ServerError } from "@ui/http";
import { canLock, lockInfo } from "./utils";

const SAVE_DEBOUNCE_TIME = 2000; // 2s
const SAVE_MAX_DEBOUNCE_TIME = 15000; // 15s

export function classicNewsletterApi(src: schema.Newsletter, userId: string): NewsletterAPI {
  const id = src.id;
  const status = writable<APIStatus>("ready");

  const store = writable<schema.Newsletter>(cloneDeep({ ...src, editors: {} }));
  let afterReloginCallback: (() => void) | undefined = undefined;

  function lastUpdate(): schema.BlockLastUpdate {
    return {
      type: "u",
      when: +new Date(),
      by: userId
    };
  }

  let _lastSaved: number | undefined = undefined;
  // setup the draft saving api
  const saveDraft = debounce(
    async (nl: schema.Newsletter) => {
      const { id, content, design } = nl;

      const m = maxSavedTime(nl);
      if (!_lastSaved || _lastSaved === m) {
        _lastSaved = m;
        return;
      }
      status.set("saving");
      try {
        const p = await minWait(
          postJson(`/app/drafts/save_json`, {
            id,
            title: nl.content.header.title,
            content,
            design,
            _v: "1",
            _e: "new" // Set the new editor
          }),
          800
        );
      } catch (e) {
        const serverError = e as ServerError;
        switch (serverError.code) {
          case "login_needed":
            afterReloginCallback = () => {
              saveDraft(nl);
              afterReloginCallback = undefined;
            };
            status.set("login-needed");
            break;
          case "locked":
            status.set("error");
            const errorText = `${
              serverError.extraData!.editor_name
            } is currently editing this newsletter and your changes can’t be saved. Click “OK” to return to the newsletter, and then ask ${
              serverError.extraData!.editor_first_name
            } to save their changes so that you can edit.`;
            alert(errorText);
            document.location = `/n/${nl.short}`;
            document.body.style.opacity = "0";
            break;
          default:
            status.set("error");
            // queue to try again in 2s
            saveDraft(nl);

            break;
        }
        return;
      }

      _lastSaved = m;
      status.set("ready");
    },
    SAVE_DEBOUNCE_TIME,
    {
      leading: true,
      trailing: true,
      maxWait: SAVE_MAX_DEBOUNCE_TIME
    }
  );

  const cleanup = store.subscribe(saveDraft);

  function idx<T extends schema.Block = schema.Block>(d: schema.Newsletter, id: string | undefined): number {
    return id ? d.content.blocks.findIndex((i) => i._id === id) : -1;
  }

  function lockableById(nl: schema.Newsletter, id: string): schema.Lockable | undefined {
    if (id === "DESIGN") {
      return nl.design;
    }

    return [nl.content.header, nl.content.logo, nl.content.footer, ...nl.content.blocks].find((b) => b && b._id === id);
  }

  const blocks = {
    commit<T extends schema.Block>(id: string, updater: BlockUpdater<T>) {
      store.update((data) => {
        const { blocks, footer, logo, header } = data.content;
        let block = [...blocks, footer, logo, header].find((b) => b && b._id == id);

        if (!block) {
          console.error("can't find block", id);
          return data;
        }

        updater(block as T);
        // clear locks
        block._li = undefined;
        block._lu = lastUpdate();

        return data;
      });
    },

    insert(newBlock: schema.Block, insertAfterId?: string) {
      // make sure we're forcing a save
      // this is because we're calculating max save time based on the *existing blocks* (facepalm)
      // this is an ugly hack *BUT* it will go away when we switch to realtime.
      _lastSaved = +new Date();
      store.update((data) => {
        data.content.blocks.splice(idx(data, insertAfterId) + 1, 0, newBlock);
        return data;
      });
    },

    move(id: string, insertAfterId?: string) {
      store.update((data) => {
        const blocks = data.content.blocks;

        const currentIdx = idx(data, id);
        const b = blocks[currentIdx];
        // remove it
        blocks.splice(currentIdx, 1);
        blocks.splice(idx(data, insertAfterId) + 1, 0, b);

        b._li = undefined;
        b._lu = lastUpdate();

        return data;
      });
    },

    remove(id: string) {
      // make sure we're forcing a save
      // this is because we're calculating max save time based on the *existing blocks* (facepalm)
      // this is an ugly hack *BUT* it will go away when we switch to realtime.
      _lastSaved = +new Date();
      store.update((data) => {
        data.content.blocks.splice(idx(data, id), 1);
        return data;
      });
    },
    duplicate(id: string) {
      store.update((data) => {
        const blocks = data.content.blocks;
        const b = cloneDeep(blocks[idx(data, id)]);

        blocks.splice(idx(data, id) + 1, 0, b);

        return data;
      });
    }
  };

  async function publish() {
    // we wait for all our changes to save
    await saveDraft.flush();

    status.set("publishing");
    const url = await postJson<string>(`/app/pages/publish_json`, {
      draft_id: id
    });
    return url;
  }

  function setDesign(design: schema.NewsletterDesign) {
    // save design
    store.update((d) => {
      d.design = design;
      d.design._li = undefined;
      d.design._lu = lastUpdate();
      return d;
    });
  }

  function lock(id: string, lock: "insert" | "edit" | "preemptive") {
    let maybeLock: schema.LockInfo | undefined;

    store.update((d) => {
      const l = lockableById(d, id);
      l && (l._li = lockInfo(lock));

      return d;
    });

    return () => {
      store.update((d) => {
        const l = lockableById(d, id);

        l && (l._li = undefined);

        return d;
      });
    };
  }

  return {
    lock,
    isCollaborative: false,
    clearLock(id: string) {
      store.update((d) => {
        const l = lockableById(d, id);
        if (canLock(l)) {
          l._li = undefined;
        }
        return d;
      });
    },
    setLogo(newLogo: schema.LogoBlock | undefined) {
      store.update((data) => {
        // set the new logo, clear locks etc.
        const l = newLogo ? { ...newLogo, _li: undefined, _lu: lastUpdate() } : newLogo;

        data.content.logo = l;
        return data;
      });
    },
    takeOver: async () => {
      store.update((d) => {
        const l = lockableById(d, d.id);
        if (l) {
          l._li = undefined;
        }
        return d;
      });

      return true;
    },
    reLogin: () => {
      status.set("login-needed");
    },
    recoverLogin: () => {
      status.set("ready");
      if (afterReloginCallback) {
        afterReloginCallback();
      }
    },
    status: { subscribe: status.subscribe },
    data: { subscribe: store.subscribe },
    blocks,
    publish,
    setDesign,
    cleanup
  };
}

// A no-op static newsletter loader
export function staticNewsletterApi(src: schema.Newsletter, userId: string): NewsletterAPI {
  const status = writable<APIStatus>("ready");
  const data = readable(cloneDeep(src));

  return {
    isCollaborative: false,
    data,
    blocks: { duplicate() {}, insert() {}, move() {}, remove() {}, commit() {} },
    cleanup() {},
    clearLock() {},
    setLogo() {},
    publish: async () => "",
    lock() {
      return () => {};
    },
    reLogin: () => {
      status.set("login-needed");
    },
    recoverLogin: () => {
      status.set("reconnecting");
    },
    status: { subscribe: status.subscribe },
    setDesign() {},
    takeOver: async () => false
  };
}

// Calculate the max update time since last change
function maxSavedTime(nl: schema.Newsletter) {
  return Math.max(
    ...([nl.design, ...nl.content.blocks, nl.content.header, nl.content.footer, nl.content.logo]
      .filter((n) => n)
      .map((n) => n?._lu?.when)
      .filter((n) => n) as number[])
  );
}
