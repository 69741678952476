<script lang="ts">
  import type { schema } from "@editor/schema";
  import Block from "./Block.svelte";
  import { getNewsletterStores } from "../newsletterStores";
  import VideoBlockWeb from "./VideoBlockWeb.svelte";

  export let block: schema.VideoBlock;
  const showActualPlayerPreview = true;
  const { design } = getNewsletterStores();
</script>

<Block
  {block}
  on:click
  on:delete
  let:layout
  calcSize={(layout) => (layout === "lg" || layout === "-" ? "full" : "box")}
  let:isEmpty
  showEmpty>
  {#if isEmpty}
    <div class="flex items-center justify-center h-64 text-gray-600 rounded-lg">
      <div class="flex flex-col items-center text-center">
        <div class="">
          <span class="text-6xl text-gray-500 material-icons-round notranslate">play_arrow</span>
        </div>
        <div>
          <h1 class="text-15">Click to add a video</h1>
          <p class="mt-1 text-13">We support YouTube, Vimeo and Viddler</p>
        </div>
      </div>
    </div>
  {:else if layout === "side-l"}
    <div class="flex items-start justify-start">
      <div
        class="relative w-1/3 h-32 bg-center bg-no-repeat bg-cover rounded"
        class:bg-gray-200={!block.thumbnail_url}
        style="background-image: url({block.thumbnail_url || ''})">
        <div class="absolute flex items-center justify-center w-full h-full">
          <div class="text-6xl text-white material-icons-round notranslate">play_arrow</div>
        </div>
      </div>
      <div class="flex flex-col justify-between h-32 ml-4">
        <div class="font-bold text-18" style="color:{$design.background.dark ? '#fff' : $design.color['text.title'].text}">
          {block.title || ""}
        </div>
        <div class="text-15" style="color:{$design.background.dark ? '#fff' : $design.color['text.title'].text}">{block.target}.com</div>
      </div>
    </div>
  {:else if showActualPlayerPreview}
    <div class="relative my-2 overflow-hidden transition-all">
      <VideoBlockWeb bind:block />
      <!-- Add transparent overlay to prevent clicks on the player -->
      <div class="absolute inset-0 bg-transparent" />
    </div>
  {:else}
    <div class="relative">
      <div class="absolute flex items-center justify-center w-full h-full">
        <div class="text-white material-icons-round notranslate text-9xl">play_arrow</div>
      </div>
      <div class="bg-center bg-no-repeat bg-cover video-block" style="background-image: url({block.thumbnail_url || ''})" />
      <div
        class="absolute bottom-0 left-0 right-0 flex items-end justify-between w-full p-2 pt-10 text-white text-14 bg-gradient-to-t from-black to-transparent">
        <div class="">{block.title || ""}</div>
        <div class="opacity-75">{block.target}.com</div>
      </div>
    </div>
  {/if}
</Block>

<style>
  .video-block {
    height: 350px;
  }
</style>
