<script lang="ts">
  import type { schema } from "@editor/schema";
  import FacebookVideo from "./web/video/FacebookVideo.svelte";
  import Fallback from "./web/video/Fallback.svelte";
  import ViddlerVideo from "./web/video/ViddlerVideo.svelte";
  import VimeoVideo from "./web/video/VimeoVideo.svelte";
  import YoutubeVideo from "./web/video/YoutubeVideo.svelte";
  import Block from "./Block.svelte";
  import SoundCloudVideo from "./web/video/SoundCloudVideo.svelte";

  export let block: schema.VideoBlock;
</script>

<Block {block} size="full" on:click on:delete on:duplicate let:isEmpty showEmpty layouts={["-"]}>
  {#if block.target === "youtube"}
    <YoutubeVideo {block} />
  {:else if block.target === "soundcloud"}
    <SoundCloudVideo {block} />
  {:else if block.target === "vimeo"}
    <VimeoVideo {block} />
  {:else if block.target === "viddler"}
    <ViddlerVideo {block} />
  {:else if block.target === "facebook"}
    <FacebookVideo {block} />
  {:else}
    <Fallback {block} />
  {/if}
</Block>

<style>
  :global(.author-show .only-screenshot) {
    display: none;
  }
</style>
