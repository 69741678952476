<script lang="ts">
  import type { schema } from "@editor/schema";
  import "@ui/dateMixins";
  import Block from "./Block.svelte";
  import { getNewsletterStores } from "../newsletterStores";
  import { withDefaults, isEditor, isShowAuthorPage } from "./utils";
  import { isRTL } from "sbelt/text";
  import { ServerError, postJson } from "@ui/http";
  import ErrorModal from "@ui/ErrorModal.svelte";
  import { evaluate } from "./poll-block-editor/durationUtils";
  import { onDestroy } from "svelte";
  import { _token } from "@ui/currentUser";
  import debounce from "lodash.debounce";
  import TrophySVG from "./icons/TrophySVG.svg";

  export let block: schema.PollBlock & { myAnswerIds?: string[] };

  let errorMsg: string | undefined = undefined;
  let daysToEndOfPoll: number = 0,
    hoursToEndOfPoll: number = 0;
  let expirationDateRefreshInterval: NodeJS.Timer | undefined = undefined;

  const { design, short } = getNewsletterStores();
  const isInEditor = isEditor();
  const isShowAuthor = isShowAuthorPage();

  let selectedAnswerIdMap: Record<string, boolean> = {};
  let submittingAnswerIds: Record<string, boolean> = {};
  let maxVoteCount: number = 0;

  $: selectedAnswerIdMap = (block.myAnswerIds || []).reduce(
    (map, answerId) => {
      map[answerId] = true;
      return map;
    },
    {} as Record<string, boolean>
  );

  $: didISelectAnyAnswerInThisPoll = Object.keys(selectedAnswerIdMap).length > 0 && !Object.keys(submittingAnswerIds).length;

  $: [blockWidthDefaults] = withDefaults(block, { question: "Click to add a title" });

  $: color = $design!.background.dark ? "#fff" : $design?.color["global.text"];
  $: backgroundColor = $design!.background.dark ? "#fff" : "#f2f4fb";
  $: borderColor = $design!.background.dark ? "#fff" : "#CBCBCF";
  $: selectedBackgroundColor = $design!.background.dark ? "#ddd" : "#e8f4f8";

  $: isQuestionOrOneAnswerRTL =
    isRTL(blockWidthDefaults.question) ||
    blockWidthDefaults.answers.filter(filterDeleted).find((answer) => isRTL(answer.text)) !== undefined;

  let { startDate: pollStartDate, started: hasStarted, endDate: pollEndDate, ended: wasEnded } = evaluate(block.dateRange);

  $: isLive = hasStarted && !wasEnded;
  $: isPollLiveAndNotInEditor = isLive && !isInEditor;

  $: showOnlyMyProgress = !blockWidthDefaults.showLiveResults && didISelectAnyAnswerInThisPoll && !isShowAuthor;
  $: shouldShowProgress =
    (blockWidthDefaults.showLiveResults && (wasEnded || didISelectAnyAnswerInThisPoll)) || showOnlyMyProgress || isShowAuthor;

  $: answerProgress = block.answers
    .filter(filterDeleted)
    .reduce((result: { [answerId: string]: number }, answer: schema.PollAnswer & { count?: number }) => {
      const answerCount = answer.count || 0;

      if (!result.total) {
        result.total = 0;
      }
      if (block.showLiveResults || isShowAuthor) {
        result[answer.id] = answerCount;
        result.total += answerCount;
        if (maxVoteCount < answerCount) {
          maxVoteCount = answerCount;
        }
      } else {
        if (selectedAnswerIdMap[answer.id]) {
          result[answer.id] = answerCount;
          result.total = answerCount;
          maxVoteCount = answerCount;
        }
      }

      return result;
    }, {});

  $: if (hasStarted && !wasEnded && !expirationDateRefreshInterval) {
    expirationDateRefreshInterval = setInterval(
      () => {
        calculateDurationToPollEnd(block.dateRange);
      },
      1000 * 60 * 5
    );
  }

  function filterDeleted(answer: schema.PollAnswer) {
    return !answer.deleted;
  }

  $: calculateDurationToPollEnd(block.dateRange);

  function calculateDurationToPollEnd(dateRange: schema.DateRange) {
    const { startDate, started, endDate, ended } = evaluate(dateRange);
    pollEndDate = endDate;
    pollStartDate = startDate;
    hasStarted = started;
    wasEnded = ended;
    isLive = hasStarted && !wasEnded;

    const now = Date.now();
    const totalMinutesToEndOfPoll = Math.floor((pollEndDate.valueOf() - now) / (1000 * 3600));
    daysToEndOfPoll = Math.floor(totalMinutesToEndOfPoll / 24);
    hoursToEndOfPoll = Math.floor(totalMinutesToEndOfPoll % 24);
  }

  const selectAnswerDebounce = debounce(selectAnswer, 200);

  async function submitAnswers(selectedAnswerId: string) {
    submittingAnswerIds[selectedAnswerId] = true;

    // Vote and update counters from result
    const [result, _] = await Promise.all([
      postJson<{ [answerId: string]: number }>("/app/polls/vote", {
        answer_ids: Object.keys(selectedAnswerIdMap),
        poll_id: block._id,
        short: short,
        _token: $_token
      }),
      new Promise((resolve) => setTimeout(resolve, 1000))
    ]);

    answerProgress = { ...result };

    delete submittingAnswerIds[selectedAnswerId];
    submittingAnswerIds = { ...submittingAnswerIds };
  }

  async function selectAnswer(answerId: string) {
    const originalAnswerIdMap = { ...selectedAnswerIdMap };

    if (block.multiAnswers) {
      if (selectedAnswerIdMap[answerId]) {
        // User has un-selecting the answer he already voted for
        delete selectedAnswerIdMap[answerId];
      } else {
        selectedAnswerIdMap[answerId] = true;
      }
    } else {
      // Single selection
      if (selectedAnswerIdMap[answerId]) {
        // User has un-selecting the answer he already voted for
        selectedAnswerIdMap = {};
      } else {
        selectedAnswerIdMap = { [answerId]: true };
      }
    }

    try {
      await submitAnswers(answerId);
    } catch (e) {
      errorMsg = ServerError.GENERIC_ERROR;
      // Reverting...
      selectedAnswerIdMap = { ...originalAnswerIdMap };
    } finally {
      delete submittingAnswerIds[answerId];
      submittingAnswerIds = { ...submittingAnswerIds };
    }
  }

  onDestroy(() => {
    if (expirationDateRefreshInterval) {
      clearInterval(expirationDateRefreshInterval);
    }
  });
</script>

<ErrorModal bind:errorMsg />

<Block bind:block size="full" on:click on:delete on:duplicate on:toggleToc let:layout let:isEmpty showEmpty layouts={["-"]}>
  <div class="relative px-5 py-4 select-none">
    <h3 class="font-bold leading-tight text-gray-600 text-18" dir={isQuestionOrOneAnswerRTL ? "rtl" : "ltr"} style="color:{color};">
      {blockWidthDefaults.question}
    </h3>
    <div class="mt-4">
      {#each blockWidthDefaults.answers.filter(filterDeleted) as answer, index (answer.id)}
        {@const hasSubmittingAnswerIds = Object.keys(submittingAnswerIds).length > 0}
        {@const didISelectThisAnswer = selectedAnswerIdMap[answer.id]}
        {@const selected = didISelectThisAnswer && !hasSubmittingAnswerIds}
        {@const selecting = didISelectThisAnswer && hasSubmittingAnswerIds}
        {@const isSubmitting = submittingAnswerIds[answer.id]}
        {@const percentage =
          showOnlyMyProgress && didISelectThisAnswer
            ? 100
            : answerProgress.total && answerProgress[answer.id]
              ? (answerProgress[answer.id] / answerProgress.total) * 100
              : 0}
        {@const backgroundForSelectedAnswer = `background-image:linear-gradient(to right, ${selectedBackgroundColor}, ${selectedBackgroundColor});background-size:${percentage}%, 0;`}
        {@const progressBackgroundStyle =
          showOnlyMyProgress && didISelectThisAnswer
            ? backgroundForSelectedAnswer
            : shouldShowProgress && !showOnlyMyProgress
              ? backgroundForSelectedAnswer
              : ""}
        <label
          dir={isQuestionOrOneAnswerRTL ? "rtl" : "ltr"}
          for={answer.id}
          class:mt-3={index > 0}
          class:live={isPollLiveAndNotInEditor}
          class:cursor-pointer={isPollLiveAndNotInEditor && !didISelectAnyAnswerInThisPoll}
          class:pointer-events-none={!isLive || isInEditor || isShowAuthor}
          class:voted={answer.count}
          class="relative flex justify-between overflow-hidden text-base bg-no-repeat border rounded answer-wrapper text-nxgray-600"
          style="--border-color:{borderColor};--bg-color:{backgroundColor};{progressBackgroundStyle}">
          {#if isPollLiveAndNotInEditor}
            <div class="absolute inset-0 h-full hover:bg-black hover:bg-opacity-5" />
          {/if}
          <div class="flex items-center justify-start p-2">
            {#if wasEnded && !isInEditor && block.showLiveResults && answer.count === maxVoteCount && maxVoteCount > 0}
              <img class="z-0 w-5 h-5 mr-2" src={TrophySVG} alt={""} />
            {/if}
            <span class="z-10 font-medium">{answer.text} </span>
          </div>
          {#if block.isDynamic && hasStarted}
            <div
              dir={isQuestionOrOneAnswerRTL ? "rtl" : "ltr"}
              class="absolute top-0 flex flex-col items-end overflow-hidden text-13 text-nxgray-600"
              class:right-0={!isQuestionOrOneAnswerRTL}
              class:left-0={isQuestionOrOneAnswerRTL}>
              <span
                class="p-2 mt-px transition-transform duration-500 transform"
                class:opacity-0={isSubmitting}
                class:translate-y-full={!blockWidthDefaults.showLiveResults && !isShowAuthor && !showOnlyMyProgress}>
                {#if (blockWidthDefaults.showLiveResults && (wasEnded || !isSubmitting)) || isShowAuthor}
                  <span class="font-medium">{Math.round(percentage)}%</span>
                  {#if selected}
                    {#if answerProgress[answer.id] && answerProgress[answer.id] > 1}
                      (You + {answerProgress[answer.id] - 1})
                    {:else}
                      (You)
                    {/if}
                  {:else}
                    ({answerProgress[answer.id] || 0})
                  {/if}
                {:else if selected}
                  <span class="z-50 text-base font-medium text-nxgray-600">Your vote</span>
                {:else}
                  &nbsp;
                {/if}
              </span>
              <span
                class="p-2 transition-transform transform"
                class:-translate-y-full={isSubmitting}
                class:opacity-0={!isSubmitting}
                class:text-green-600={selecting}
                class:text-red-500={!selecting}>
                {#if isSubmitting}
                  {selecting ? "Voting..." : "Clearing Vote..."}
                {/if}
              </span>
            </div>
            {#if !isShowAuthor && !blockWidthDefaults.showLiveResults && !selected && !selecting && !(didISelectAnyAnswerInThisPoll && !isSubmitting) && !isSubmitting}
              <div
                class="absolute inset-0 flex items-center w-full px-2 text-base font-medium opacity-0 text-nxgray-600 hover:opacity-100 hover:bg-black hover:bg-opacity-5"
                class:justify-end={!isQuestionOrOneAnswerRTL}
                class:justify-start={isQuestionOrOneAnswerRTL}>
                Vote
              </div>
            {/if}
            <input
              class="answer-selector"
              type="button"
              name={block._id}
              id={answer.id}
              hidden
              on:click={() => selectAnswerDebounce(answer.id)} />
          {/if}
        </label>
      {/each}
    </div>
    <div class="flex items-center justify-between mt-4 text-base text-nxgray-400">
      <div class="flex items-center">
        <span class="mr-1 material-icons notranslate text-18 text-nxgray-300">date_range</span>
        {#if isLive}
          {#if daysToEndOfPoll > 0}
            Voting ends in {daysToEndOfPoll} days
          {:else}
            Voting ends in {hoursToEndOfPoll} hours
          {/if}
        {:else if wasEnded}
          Voting ended on {pollEndDate.toFormat("{MMMM} {Do}, {YYYY}")}
        {:else if !hasStarted}
          Voting available from {pollStartDate.toFormat("{MMMM} {Do}")} until {pollEndDate.toFormat("{MMMM} {Do}")}
        {/if}
      </div>
      {#if !isInEditor && (wasEnded || blockWidthDefaults.showLiveResults || isShowAuthor) && answerProgress.total > 0}
        <span class="text-base font-semibold text-nxgray-600">{answerProgress.total} Votes</span>
      {/if}
    </div>
    <div class="flex items-center justify-start mt-2">
      <span class="mr-1 material-icons notranslate text-18 text-nxgray-300">remove_red_eye</span>
      <span class="text-nxgray-400 text-15">
        {#if blockWidthDefaults.showLiveResults}
          Votes are anonymous but results are public
        {:else}
          Votes are anonymous and results are private
        {/if}
      </span>
    </div>
    {#if block.isDynamic && isShowAuthor}
      <div class="absolute inset-0 z-20 flex-col items-center justify-center hidden bg-white show-author-overlay bg-opacity-80">
        <span class="text-4xl material-icons notranslate text-nxgray-300">poll</span>
        <a
          on:click|stopPropagation={() => false}
          class="mt-1 text-base font-normal text-nxgray-400 hover:underline"
          href="/app/pages/preview/{short}"
          rel="noreferrer noopener"
          target="_blank">Click to see live version</a>
      </div>
    {:else if !hasStarted && !isInEditor}
      <div class="absolute inset-0 z-20 flex flex-col items-center justify-center bg-white bg-opacity-80 hide-in-show-author-page">
        <span class="text-4xl material-icons notranslate text-nxgray-300">poll</span>
        <div class="mt-1 text-base font-normal text-nxgray-400">Voting available from</div>
        <div class="text-base font-normal text-nxgray-400">
          {pollStartDate.toFormat("{MMMM} {Do}")} until {pollEndDate.toFormat("{MMMM} {Do}")}
        </div>
      </div>
    {/if}

    {#if block.isDynamic && wasEnded && !isShowAuthor}
      <div
        class="absolute inset-0 z-20 flex-col items-center justify-center hidden bg-white vote-ended-overlay bg-opacity-80 hide-in-show-author-page">
        <span class="text-4xl material-icons notranslate text-nxgray-300">poll</span>
        Voting ended on {pollEndDate.toFormat("{MMMM} {Do}, {YYYY}")}
      </div>
    {/if}
  </div>

  <!-- Overlay -->
  {#if !block.isDynamic && !isInEditor}
    <div
      class="absolute inset-0 z-20 flex items-center justify-center w-full h-full bg-transparent select-none no-print hide-in-show-author-page"
      style="backdrop-filter: blur(2px)!important">
      <div class="px-3 py-1 bg-white border border-gray-300 rounded opacity-100 text-14">
        <span class="animate-pulse">Loading...</span>
      </div>
    </div>
  {/if}
</Block>

<style>
  .answer-wrapper {
    border-color: var(--border-color);
  }
  .live.answer-wrapper:hover {
    /* background-color: var(--bg-hover-color); */
    cursor: pointer;
  }
  .answer-selector:checked ~ * {
    transform: translateX(0);
    opacity: 1;
  }

  :global(.author-show .hide-in-show-author-page),
  :global(.print-page .hide-in-show-author-page) {
    display: none;
  }
  :global(.block-wrapper:hover) .vote-ended-overlay {
    display: flex;
  }
  :global(.block-wrapper:hover) .show-author-overlay {
    display: flex;
  }
</style>
