<script lang="ts">
  import type { schema } from "@editor/schema";
  import { editDesign, createDesign, storeExportedContent } from "@editor/app/canva";
  import Tooltip from "@ui/Tooltip.svelte";
  import Switch from "@ui/Switch.svelte";
  import Spinner from "@ui/Spinner.svelte";
  import Hint from "@ui/Hint.svelte";
  import FieldLabel from "@ui/form/fields/FieldLabel.svelte";
  import { field } from "../form/store";
  import PhotoField from "../form/PhotoField.svelte";
  import { preload } from "sbelt/image";
  import ErrorModal from "@ui/ErrorModal.svelte";

  const { id, setWait } = field();

  export let value: schema.HeaderBlock["custom"] = undefined;

  let isProcessing: boolean = false;
  let errorMsg: string | undefined;

  function removeHeader() {
    photoValue = value = undefined;
  }

  async function onDesignPublish(o: {
    // The ID of the user's design.
    designId: string;
    // The title of the user's design.
    designTitle: string;
    // The URL for downloading the user's design. This URL expires after 15 minutes. The URL is in the form https://export-download.canva.com/<export_file>
    exportUrl: string;
  }) {
    isProcessing = true;

    // const ocr = detectText(o.exportUrl);
    const { url, h } = await storeExportedContent(o.exportUrl);

    value = {
      showText: value?.showText ?? false,
      externalId: o.designId,
      url,
      h: h / 2 // save it in "logical pixels" instead of retina
    };

    // await ocr;
    isProcessing = false;
  }

  $: setWait(isProcessing && "Processing background");

  let showText: boolean = value?.showText ?? false;

  $: if (value) {
    value.showText = showText;
  }

  async function editOrCreateCanvaDesign() {
    const id = value?.externalId;

    return id
      ? editDesign({
          design: { id },
          onDesignPublish,
          editor: {
            publishLabel: "Save to Smore"
          }
        })
      : createDesign({
          design: { type: "Banner", title: "Smore Newsletter Header" },
          editor: {
            publishLabel: "Save to Smore"
          },
          onDesignPublish
        });
  }

  let isUploading = false;

  function uploadStart(e: any) {
    isProcessing = true;
    isUploading = true;
  }

  function uploadDone(e: any) {
    isUploading = false;
    isProcessing = false;

    const url = e.detail.legacy_thumb_url;
    const h = e.detail.thumb_h || 700 / 2;

    // preload the image
    preload(url);

    value = {
      showText: value?.showText ?? false,
      photo: e.detail,
      externalId: "",
      url,
      h
    };
  }

  function uploadEnd(e: any) {
    setTimeout(() => {
      isUploading = false;
      isProcessing = false;
    }, 100);

    errorMsg = e?.detail?.errorMessage;
  }

  let photoValue: any = value?.photo;
  let uploadedPhoto: any | undefined;

  const ASPECT = {
    min: { w: 700, h: 280 },
    max: { w: 700, h: 350 },
    default: { w: 700, h: 300 }
  };
</script>

<ErrorModal bind:errorMsg title="" />

<div class="flex items-center mb-1">
  <FieldLabel name="Custom header" full={false} />
  <hr class="flex-grow ml-2" />
</div>
<div class="pb-1 pl-px">
  {#if value || isProcessing}
    <div class="relative flex">
      {#if isProcessing}
        {#if isUploading}
          <div class="self-start flex-shrink-0 mr-3 bg-gray-500 rounded" style="width:126px; height:70px">
            <PhotoField
              bind:value={photoValue}
              handoffId="custom-bg-upload"
              on:upload_done={uploadDone}
              on:upload_end={uploadEnd}
              on:logged_out
              ar={ASPECT}
              mode="resize"
              showErrorMessage={false}>
              <svelte:fragment slot="preview">
                <div style="width:126px; height:70px" />
              </svelte:fragment>

              <!-- <img src={previewUrl} height="70" alt="Custom background preview" class="bg-gray-500 rounded " slot="preview" let:previewUrl /> -->
            </PhotoField>
          </div>
        {:else}
          <div class="flex items-center justify-center flex-shrink-0 mr-3 bg-gray-500 rounded" style="width:126px; height:70px">
            <Spinner />
          </div>
        {/if}
      {:else if value}
        {#if value.externalId}
          <div class="self-start flex-shrink-0 mr-3">
            <img src={value.url} width="126" height="70" alt="Custom background preview" class="bg-gray-500 rounded" />
          </div>
        {:else}
          <div class="flex self-start justify-center flex-shrink-0 mr-3 bg-gray-500 rounded" style="width:126px; height:70px">
            <PhotoField
              bind:value={photoValue}
              handoffId="custom-bg-upload"
              on:upload_done={uploadDone}
              on:upload_end={uploadEnd}
              on:logged_out
              disabled
              bind:this={uploadedPhoto}
              ar={ASPECT}
              mode="resize"
              showErrorMessage={false}>
              <img
                src={previewUrl}
                style="max-height:70px; max-width:126px;"
                alt="Custom background preview"
                class="h-full bg-gray-500 rounded"
                slot="preview"
                let:previewUrl />
            </PhotoField>
          </div>
        {/if}
      {/if}

      <i class="absolute material-icons top-1 right-1 md-18 clickable hover:text-red-700" on:click={removeHeader}
        >close

        <Tooltip>Remove custom header</Tooltip>
      </i>

      <div class="flex flex-col items-start justify-between text-14">
        {#if isProcessing}
          Processing...
        {:else if value && value.externalId}
          <!-- We already have custom background here -->
          <!-- We have an external id, edit with canva -->
          <span on:click={editOrCreateCanvaDesign} class="shadow clickable canva-button"><span />Edit header with Canva</span>
        {:else if value}
          <span on:click={() => uploadedPhoto.edit()} class="p-2 text-white bg-blue-600 rounded shadow clickable"
            ><span />Edit custom header</span>
        {/if}

        <div class="flex items-center mt-3">
          <Switch id="showtext" size="small" align="start" bind:checked={showText}>
            <div class="-mt-0.5">
              <h2 class="flex items-center font-medium text-14">
                Display header text &nbsp;<Hint>Turn this off if your custom header already includes text</Hint>
              </h2>
            </div>
          </Switch>
        </div>
      </div>
    </div>
  {:else}
    <!-- Empty state -->
    <div class="flex">
      <span on:click={editOrCreateCanvaDesign} class="w-1/2 mr-1 shadow clickable canva-button"><span />Create header with Canva</span>

      <div class="relative w-1/2 ml-1">
        <PhotoField
          ar={ASPECT}
          value={undefined}
          handoffId="custom-bg-upload"
          on:upload_start={uploadStart}
          on:upload_done={uploadDone}
          on:logged_out
          on:upload_end={uploadEnd}
          mode="resize"
          showErrorMessage={false}>
          <div
            slot="empty"
            class="flex items-center justify-center h-10 font-medium bg-white border-2 border-gray-300 border-dashed rounded shadow hover:border-teal-500 clickable text-14">
            + Upload your own
          </div>
        </PhotoField>
      </div>
    </div>
  {/if}
</div>

<style>
  .canva-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    background: #7d2ae8;
    border-radius: 4px;
    padding-right: 12px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
  }

  .canva-button:hover {
    background-color: #8d39fa;
  }

  .canva-button:active {
    background-color: #6718cf;
  }

  .canva-button span {
    position: relative;
    background-size: cover;
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    fill: #fff;
    height: 24px;
    width: 24px;
    margin: 6px 7px 7px 10px;
  }

  .canva-button span:after {
    content: " ";
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: transparent;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.1 14a.5.5 0 0 0-.4-.4.4.4 0 0 0-.4.2l-.1.2-.1.2c-.5.7-.8 1-1.7 1.5-.4.2-.9.3-1.3.3-1.3 0-2-1.1-2.3-2.1-.4-1.7.3-3.9 1.3-5.1.5-.7 1-1.1 1.6-1.1a.9.9 0 0 1 .7.4c.3.4.4.8 0 1.4a.5.5 0 0 0 .2.6c.3.1.6 0 1-.5.3-.5.3-1.3 0-1.8-.4-.7-1.2-1-2-1-.7 0-1.3.1-1.8.4a6.8 6.8 0 0 0-2.8 7c.3 1 .8 1.8 1.4 2.3a4 4 0 0 0 2.3.8c1.1 0 2-.4 2.5-.8a5.6 5.6 0 0 0 1.7-1.8l.1-.2a.7.7 0 0 0 .1-.5zm-4.4 3z' fill='%23fff'/%3E%3C/svg%3E");
  }

  .canva-button span:before {
    background-color: #00c4cc;
    content: " ";
    display: block;
    position: absolute;
    border-radius: 50%;
    overflow: hidden;
    width: 83.33333%;
    height: 83.33333%;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    transition: background-color 0.1s linear;
  }
</style>
