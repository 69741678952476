import { postJson } from "@ui/http";

// 
//  Helpers for loading and using the canva API
// 
// 


const API_KEY = import.meta.env.DEV ? "mIl1o-EF7aytIjzXGGATa850" : "389eiXaK3DLFIjQM47szknkx";

// @see https://docs.developer.canva.com/button/javascript/api-reference/create-design
export type CreateDesignOptions = {
    // Options for the user's design.
    design: {
        // The type of design to create.
        type: string,

        dimensions?: {
            // The unit of measurement for the height and width properties
            units?: "px",
            // The height of the design. The unit of measurement is determined by the units property.
            width?: number,
            // The width of the design. The unit of measurement is determined by the units property.
            height?: number, 
        },

        // The title of the design. The default title is Untitled design. The title is used in the exported design's filename. Users can change the title later in the Canva editor.
        title?: string
    },
    // Options for the Canva editor.
    editor?: {
        // A label for the Publish button. This overrides the default value of "Publish".
        publishLabel?: string,
        // A file type for the exported design. The accepted values include png, pdf, jpeg, and jpeg. The ability to export designs as mp4 and gif is an invite-only feature. The default value is png.
        fileType?: "png" | "pdf" | "jpeg"
    },

    // A function that runs when a user openst heir design. For more information, see onDesignOpen.
    onDesignOpen?: DesignOpenCallback,
    // A function that runs when the user publishes their design. For more information, see onDesignPublish.
    onDesignPublish?: DesignPublishCallback,
    // A function that runs when the user closes their design. For more information, see onDesignClose.
    onDesignClose?: DesignCloseCallback
}

// A function that runs when a user opens a design. This includes when a user creates a new design or edits an existing design.
// @see https://docs.developer.canva.com/button/javascript/api-reference/on-design-open
type DesignOpenCallback = (o: {
    // The ID of the user's design.
    designId: string;
    // The title of the user's design.
    designTitle: string;
}) => void;

// A function that runs when a user publishes a design.
// @see https://docs.developer.canva.com/button/javascript/api-reference/on-design-publish
type DesignPublishCallback = (o: {
    // The ID of the user's design.
    designId: string;
    // The title of the user's design.
    designTitle: string;
    // The URL for downloading the user's design. This URL expires after 15 minutes. The URL is in the form https://export-download.canva.com/<export_file>
    exportUrl: string;
}) => void;

// A function that runs when a user closes a design.
// @see https://docs.developer.canva.com/button/javascript/api-reference/on-design-close
type DesignCloseCallback = () => void;


// @see https://docs.developer.canva.com/button/javascript/api-reference/edit-design
export type EditDesignOptions = {
    // Options for the user's design.
    design: {
        // The ID of the user's design.
        id: string,
    },
    // Options for the Canva editor.
    editor?: {
        // A label for the Publish button. This overrides the default value of "Publish".
        publishLabel?: string,
        // A file type for the exported design. The accepted values include png, pdf, jpeg, and jpeg. The ability to export designs as mp4 and gif is an invite-only feature. The default value is png.
        fileType?: "png" | "pdf" | "jpeg"
    },

    // A function that runs when a user openst heir design. For more information, see onDesignOpen.
    onDesignOpen?: DesignOpenCallback,
    // A function that runs when the user publishes their design. For more information, see onDesignPublish.
    onDesignPublish?: DesignPublishCallback,
    // A function that runs when the user closes their design. For more information, see onDesignClose.
    onDesignClose?: DesignCloseCallback
}


export type CanvaAPI = {
    createDesign(opts: CreateDesignOptions): void;
    editDesign(opts: EditDesignOptions): void;
}


let _apiPromise: Promise<CanvaAPI> | undefined;

// load and return the canva api object
async function canvaApi(): Promise<CanvaAPI>{
    if(!_apiPromise){
        _apiPromise = new Promise((resolve, reject) => {
            ((document, url) => {
                const script = document.createElement("script");
                script.src = url;
                script.onload = () => {
                  
                    (async () => {
                        // @ts-ignore
                        if (!window.Canva || !window.Canva.DesignButton) {
                          return;
                        }
                      
                        // @ts-ignore
                        const api = await window.Canva.DesignButton.initialize({
                          apiKey: API_KEY,
                        });

                        resolve(api)
                      })();

                };
                script.onerror = reject;
                document.body.appendChild(script);
              })(document, "https://sdk.canva.com/designbutton/v2/api.js");
        })
    }

    return _apiPromise;
}


const FAKE_MODE = false;


export async function createDesign(opts: CreateDesignOptions){
    return FAKE_MODE ? fakeCreateDesign(opts) : (await canvaApi()).createDesign(opts);
}

export async function editDesign(opts: EditDesignOptions){
    return FAKE_MODE ? fakeEditDesign(opts) : (await canvaApi()).editDesign(opts);
}


let _fakeId: number = 0;
function fakeCreateDesign(opts: CreateDesignOptions){
    // do nothing and just call publish design

    document.body.style.opacity = "0";

    setTimeout(() => {
        opts.onDesignPublish && opts.onDesignPublish({
            designId: `fake_design_${_fakeId++}`,
            designTitle: `Fake design!`,
            exportUrl: "https://i.imgur.com/qcs04e4.png"
        })

        document.body.style.opacity = "1";
    }, 1000)
}

function fakeEditDesign(opts: EditDesignOptions){
    // do nothing and just call publish design

    document.body.style.opacity = "0";

    setTimeout(() => {
        opts.onDesignPublish && opts.onDesignPublish({
            designId: opts.design.id,
            designTitle: `Fake design!`,
            exportUrl: "https://i.imgur.com/qcs04e4.png"
        })

        document.body.style.opacity = "1";
    }, 1000)
}



export async function storeExportedContent(exportUrl: string): Promise<{url: string, h:number}> {
    const {url, h} = await postJson<{url: string,h: number}>("/app/embed/store_canva_export", {exportUrl})


    return {url, h}
}